<template>
    <div>
        <form action="https://museudofutebol.org.br/crfb/busca" method="get" ref="crfbForm" @submit="beforeSubmit">
            <!-- <label id="crfb-search-input__label" class="crfb-search-input__label">Pesquise no acervo</label> -->
            <div class="crfb-search-input-wrapper">
                <Autocomplete 
                    name="crfb_search"
                    :search="search"
                    ref="searchAutocomplete"
                    base-class="crfb-search-input"
                    placeholder="Pesquise no acervo"
                    aria-label="Pesquise no acervo"
                    auto-select
                    @submit="valueSelected"
                    :debounce-time="500"
                    :get-result-value="getResultValue"
                >
                    <template #result="{ result, props }">
                        <li v-bind="props">
                            <dl>
                                <dt><span class="suggestion-label">{{ result.st_entidade }}</span></dt>
                                <dd><span class="suggestion-type">{{ label(result.cd_sistema) }}</span></dd>
                            </dl>
                        </li>
                    </template>

                </Autocomplete>
                <span v-if="!spinner" class="crfb-search-input-icon"><i class="icon-search-fine"></i></span>
                <div v-if="spinner" class="crfb-search-input-spinner">
                    <div class="loader">Loading...</div>
                </div>
                
                <input type="hidden" name="crfb_search_page" value="1">
                <input type="hidden" name="crfb_search_filter_rels" :value="selectedId">
            </div>
        </form>

        <div v-if="keywords.length > 0" class="crfb-search__suggested-keywords">
            <ul>
                <li 
                    class="crfb-search__suggested-keyword"
                    v-for="(keyword, i) in keywords"
                    v-bind:key="i"
                    @click="suggestedKeywordSelected(keyword)"
                >
                    {{ keyword.label }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue';
const MIN_AUTOCOMPLETE_LENGTH = 4;

export default {

    props: {
        submit: Boolean,
        placeholderImageUrl: String
    },

    data() {
        return {
            searchTerms: '',
            selectedId: undefined,
            spinner: false,
            keywords: []
        };
    },

    mounted() {
        fetch('https://museudofutebol.org.br?crfb_api_params=[]&crfb_api_endpoint=vc_destaques')
        .then((response) => response.json())
        .then((data) => {
            this.keywords = data;
            console.log(data);
        });
    },

    methods: {
        search(q) {
            return new Promise((resolve) => {
                if (q.length < MIN_AUTOCOMPLETE_LENGTH) {
                    return resolve([])
                }

                this.spinner = true;
                fetch('https://museudofutebol.org.br?crfb_search_ac=' + encodeURIComponent(q))
                .then((response) => response.json())
                .then((data) => {
                    data.push({
                        cd_entidade: 0,
                        cd_sistema: 0,
                        st_entidade: q
                    });
                    this.spinner = false;
                    resolve(data);
                });
            });
        }, 

        label(key) {
            const map = {
                0 : 'Busca textual',
                137 : 'Palavra-chave',
                344 : 'Personalidade',
                17318 : 'Instituição',
                20700 : 'Evento'
            };
            return map[key];
        },

        getResultValue(result) {
            return result.st_entidade;
        },

        valueSelected(result) {
            if (result) {
                this.selectedId = result.cd_entidade;
                this.searchTerms = result.st_entidade;
                this.$emit('value-selected', {
                    term_id: this.selectedId,
                    label: this.searchTerms,
                    type: this.selectedId > 0 ? 'rels' : 'string'
                });
    
                if (result.cd_sistema > 0) {
                    this.$refs.searchAutocomplete.value = '';
                }
            }

            if (this.submit) {
                this.$nextTick(() => {
                    this.$refs.crfbForm.submit();
                });
            }
        },

        suggestedKeywordSelected(keyword) {
            this.selectedId = keyword.term_id;
            this.$emit('value-selected', {
                term_id: keyword.term_id,
                label: keyword.label,
                type: 'rels'
            });

            if (this.submit) {
                this.$nextTick(() => {
                    this.$refs.crfbForm.submit();
                });
            }
        },

        beforeSubmit(e) {
            if (!this.submit) {
                e.preventDefault();
                e.stopPropagation();
            }
            const valLength = this.$refs.searchAutocomplete.value.length;
            if (valLength && valLength < MIN_AUTOCOMPLETE_LENGTH) {
                this.$emit('value-selected', {
                    term_id: 0,
                    label: this.$refs.searchAutocomplete.value,
                    type: 'string'
                });
            }
        },
    },

    components: {
        Autocomplete
    }
}
</script>

<style lang="scss">
.crfb-search-input-wrapper {
    position: relative;
    max-width: 780px;
}
.crfb-search-input-input {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    outline: none;
    border: 3px solid #333333;
    padding: 10px 40px 10px 10px;
    font-size: 16px !important;
}


.crfb-search-input-wrapper .crfb-search-input-result-list {
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    margin: 0;
    padding: 10px;
    list-style: none;
    width: 100%;
    max-width: 780px;
    min-height: 88px;
    max-height: 480px;
    overflow-y: auto;
    background-color: #ffffff;
}

.crfb-search-input-result {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
}
.crfb-search-input-result:hover,
.crfb-search-input-result[aria-selected=true]{
    background-color: #eee;
}

.crfb-search-input-result dl, 
.crfb-search-input-result dt, 
.crfb-search-input-result dd {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    float: none;
    font-weight: 400;
}

.crfb-search-input-result dt .suggestion-label {
    font-weight: 700;
}

dl > dt:first-of-type, 
dl > dd:first-of-type {

}

// Results list, active filter "tags"
.crfb-search__suggested-keywords {

    ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 8px 0;
    }

    .crfb-search__suggested-keyword {
        // position: relative;
        background: #333333;
        color: #ffffff;
        padding: 6px;
        margin: 0 8px 0 0;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        cursor: pointer;
    }
}


.crfb-search-input-spinner {
    position: absolute;
    top: 12px;
    right: 12px;

    .loader {
        font-size: 10px;
        margin: 0;
        text-indent: -9999em;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #ccc;
        background: -moz-linear-gradient(left, #ccc 10%, rgba(255, 255, 255, 0) 42%);
        background: -webkit-linear-gradient(left, #ccc 10%, rgba(255, 255, 255, 0) 42%);
        background: -o-linear-gradient(left, #ccc 10%, rgba(255, 255, 255, 0) 42%);
        background: -ms-linear-gradient(left, #ccc 10%, rgba(255, 255, 255, 0) 42%);
        background: linear-gradient(to right, #ccc 10%, rgba(255, 255, 255, 0) 42%);
        position: relative;
        -webkit-animation: load3 1.4s infinite linear;
        animation: load3 1.4s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }
    .loader:before {
        width: 50%;
        height: 50%;
        background: #ccc;
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
    }
    .loader:after {
        background: #ffffff;
        width: 75%;
        height: 75%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    @-webkit-keyframes load3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
        }
        @keyframes load3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}
</style>