<template>
    <div class="mio-search__container">
        <slot>
            <div class="mio-search__search-filter">
                <busca-autocomplete :submit="true"></busca-autocomplete>
            </div>
        </slot>
    </div>
</template>

<script>
import BuscaAutocomplete from "./components/BuscaAutocomplete.vue";

export default {
    data() {
        return {};
    },

    components: {
        BuscaAutocomplete,
    },
};
</script>

<style></style>
